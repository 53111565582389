import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const SecondPage = ({ data }) => (
  <Layout>
	<SEO title="Takasivu" />
	
	{ data.allFile.edges.sort( (a,b) => ( new Date(b.node.mtime)) - (new Date(a.node.mtime))).map(({ node }, index) => (
		<div style={{ float: `left`, padding: '1rem' }}>
		{ /*		<p> {node.name } </p> */ }
		<Img fixed={node.childImageSharp.fixed} />
		</div>
	))}
	    <hr         style={{
		clear: 'both',
		margin: `0 auto`,
	    marginBottom: '0.5rem',
            maxWidth: 960,
            paddingTop: 0,
          }}
	    />

  </Layout>
)

export default SecondPage

export const query = graphql`
query {
  allFile (filter: { sourceInstanceName: { eq: "gallery" } }){
    edges {
      node{
        name
        mtime
        absolutePath
        sourceInstanceName
        childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
          fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }

      }
    }
  }
}
`
